<template>
	<el-pagination
		background
		:current-page.sync="currentPage"
		:page-sizes="pageSizes"
		:page-size.sync="currentPageSize"
		layout="slot,sizes,prev, pager, next, jumper"
		:page-count="totalPage"
		:hide-on-single-page="false"
		@size-change="handleSizeChange"
		@current-change="handleCurrentChange"		
	>
		<em class="total-count">总共{{ totalCount }}条记录</em>
	</el-pagination>
</template>

<script>
export default {
	name: "Pagination",
	props: {
		showhomepage: {
			type: Boolean,
			default: true,
		},
		pageSize: {
			type: Number,
			default: () => 10,
		},
		totalPage: {
			type: Number,
			default: () => 10,
		},
		page: {
			type: Number,
			default: () => 1,
		},
		pageSizes: {
			type: Array,
			default: () => [10, 20, 50],
		},
		totalCount: {
			type: [Number, String],
			default: () => 0,
		},
		scroll: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		handleSizeChange(val) {
			if (this.scroll) {
				// scrollTo(0, 800)
			}
			this.$emit("pageSizeChange", val)
		},
		handleCurrentChange(val) {
			if (this.scroll) {
				// scrollTo(0, 800)
			}
			this.$emit("pageChange", val)
		},
		goFirst() {
			this.currentPage = 1
			this.handleCurrentChange()
		},
	},
	computed: {
		currentPage: {
			get() {
				return this.page
			},
			set(val) {
				this.$emit("update:page", val)
			},
		},
		currentPageSize: {
			get() {
				return this.pageSize
			},
			set(val) {
				this.$emit("update:pageSize", val)
			},
		},
	},
}
</script>

<style lang="scss">
// @import "@/assets/css/variable.scss";

.el-pagination {
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
	border-radius: 50%;
}


// .el-pagination.is-background .el-pager li:not(.disabled).active {
// 	background: $primary_color;
// 	color: #fff;
// 	font-weight: 400;
// }

// .el-pagination.is-background .el-pager li:not(.active) {
// 	color: #6c7179;
// 	background: #f8f9fb;
// 	font-weight: 400;

// 	&:hover {
// 		color: $primary_color;
// 	}
// }

.total-count {
	font-size: 13px;
	margin-right: 10px;
}
</style>
