<template>
  <transition name="el-fade-in">
    <div class="drawer-mask" v-show="visible">
      <div class="drawer-container">
        <transition name="drawer">
          <div class="drawer-content">
            <div class="drawer-header">
              <h2 class="header-title">选择学员</h2>
              <!-- <button @click="getTableData">get数据</button> -->
              <!-- <button @click="findSeled">查看所有选中的值</button> -->
            </div>
            <div class="drawer-body">
              <div class="goods-form-wrapper">
                <!-- 搜索表单区 -->
                <el-form class="formContent" :model="orderModalFields" :rules="orderModalRules" label-position="right" label-width="120px" inline>
                  <el-form-item
                    class="searchItem"
                    v-for="item of orderModalItems"
                    :key="item.label"
                    :label="item.label"
                    :style="item.itemStyle"
                    :prop="item.prop"
                  >
                    <el-input
                      class="normal-input"
                      style="width:162px;"
                      clearable
                      v-model="orderModalFields[item.prop]"
                      :disabled="item.disabled"
                      :placeholder="item.placeholder"
                      :suffix-icon="item.suffixIcon"
                    />
                  </el-form-item>
                  <div >
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="onReset">重置</el-button>
                  </div>
                </el-form>
              </div>
              <div class="goods-table-wrapper" >
                <!-- 学员信息区 -->
                <el-table
                   :data="tableData"
                    style="width: 100%; margin-bottom: 24px"
                    border
                    sortable
                    highlight-current-row
                    header-cell-class-name="normal-table-header"
                    height="calc(100% - 140px)"
                    @current-change="onCurrentChange"
                >
                  <!-- <el-table-column :show-overflow-tooltip="true" type="selection"  :selectable="selectable"></el-table-column>  -->
                  <!-- <el-table-column :show-overflow-tooltip="true" type="selection"  slot-scope="scope" v-if="!scope.row.type || scope.row.type == null"></el-table-column>  -->
                  <!-- <el-table-column :show-overflow-tooltip="true" type="selection" v-if="!checkStatus && checkStatus != 1"></el-table-column> -->
                  <template v-for="item of columns">
                    <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'sex'" :label="item.label" :key="item.prop" width="60px">
                      <template v-slot:default="{ row }">
                        <p>{{ studentSexMap[row.sex] }}</p>
                      </template>
                    </el-table-column>

                    <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'status'" :label="item.label" :key="item.prop" width="100px">
                      <template v-slot:default="{ row }">
                        <p>{{ studentAccMap[row.status] }}</p>
                      </template>
                    </el-table-column>

                    <el-table-column :show-overflow-tooltip="true"
                      v-else
                      :type="item.type"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                      :fixed="item.fixed"
                      :width="item.width || '160px'"
                    />
                  </template>
                </el-table>
                <Pagination
                  :page.sync="page"
                  :pageSize.sync="pageSize"
                  :totalPage="totalPage"
                  :totalCount="totalCount"
                  @pageChange="pageChange"
                  @pageSizeChange="pageSizeChange"
                />
              </div>
            </div>
            
            <div class="drawer-footer">
              <el-button @click="onCancel">返回</el-button>
              <el-button type="primary" @click="onOk" :disabled="isCurrentRow">确定</el-button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { orderStatusMap , studentSexMap , studentAccMap } from '../constants'
import Pagination from '../components/pagination.vue'
export default {
  name: "OrderModal",
  props: {
    visible: Boolean,
    aftersaleType: [Number, String],
    orderModalFields: Object,
    orderModalRules: Object,
    orderModalItems: Array,
    // selectMode 0 单选 1 多选
    selectMode: String
  },
  components: {
    Pagination
  },
  data() {
    return {
      expandRowKeys:[],
      isAllSelect:false,
      currentRadioIndex: "",
      tableData:[],
      isCurrentRow:true,
      seledReceiveData:[],//已选择收支项目类目
      columns: [
        { label: "学员ID", prop: "userId" },
        { label: "姓名", prop: "userName" },
        { label: "学员账号", prop: "userPhone" },
        { label: "身份证号", prop: "idCard" },
        // { label: "性别", prop: "sex" },
        // { label: "账号状态", prop: "status" },
        // { label: "注册时间", prop: "creationTime" },
        // { label: "最后访问时间", prop: "lastLoginTime" },
      ],
      page: 1,
      pageSize: 10,
      totalPage: 1,
      totalCount: 1,
      goodsCateModalVisible: false,
      currentRow: {},
      selectRows: [],
      orderStatusMap,
      studentSexMap,
      studentAccMap
    }
  },
  methods: {
    onSelectChange() {
      console.log(111)
    //   console.log(this.currentRow)
    //   let sel = this.$refs.multipleTable.selection;
    //   this.isCurrentRow=  sel.length >0 ? false:true
    //   this.seledReceiveData = sel
    //   console.log("所有选中的值",sel)
    },

    selectable(row, index) { //是否可选收支项目         
      if(row.children == null){
          return true
      }else {
          return false
      }
    },

    onCurrentChange(row) {
      this.currentRow = row
      console.log("选择",row);
    },
 
    onCancel() {
      this.$emit("on-cancel")
    },

    onOk() {
      if (this.selectMode == "0") {
        console.log("单选")
        // const row = JSON.parse(JSON.stringify(this.currentRow))
        // this.$emit("on-ok", row)
      } else {
        // const rows = JSON.parse(JSON.stringify(this.selectRows))
        // this.$emit("on-ok", rows)
        this.$emit("on-ok",  this.currentRow)
      }
    },

    // 查询
    onSearch() {
      this.getTableDataset()
    },

    // 重置
    onReset() {
      Object.keys(this.orderModalFields).forEach(key => (this.orderModalFields[key] = ""))
      this.getTableData()
    },

    // 获取订单列表表格数据
    async getTableDataset() {
      // let project = auth.getUserInfo()
      const params = {
        ...this.orderModalFields,
        // deptIds: project.userDetails.deptIds,
        // manageDepts: project.userDetails.manageDepts,
        schoolId:localStorage.getItem("deptNcId"),
      }

      console.log("表格的参数",params);
      const { data , pageInfo } = await this.$fetch("getUserListByGet", params)
      this.tableData = data
      this.totalPage = pageInfo.pageCount
      this.totalCount = pageInfo.total
    },

    pageChange() {
      console.log("pageChange")
      this.currentRadioIndex = ""
      this.getTableData()
    },
    pageSizeChange() {
      console.log("pageSizeChange")
      this.currentRadioIndex = ""
      this.page = 1
      this.getTableData()
    },
    // 商品分类弹窗取消回调
    onGoodsCateCancel() {
      this.goodsCateModalVisible = false
    },

    // 商品分类弹窗确定回调
    onGoodsCateOk(cateRow) {
      this.goodsForm.goodsCategoryName = cateRow.name
      this.goodsCateModalVisible = false
    },

    // 获取订单列表表格数据
    async getTableData() {
      console.log("翻页")
      let project = auth.getUserInfo()
      const params = {
        ...this.orderModalFields,
        curPage: this.page,
        pageSize: this.pageSize,
        deptIds: project.userDetails.deptIds,
        manageDepts: project.userDetails.manageDepts,
        schoolId:localStorage.getItem("deptNcId"),
        // aftersaleType: this.aftersaleType
      }

      console.log("表格的参数",params);
      const { data, code , pageInfo } = await this.$fetch("getUserListByGet", params)
      this.tableData = data
      this.totalPage = pageInfo.pageCount
      this.totalCount = pageInfo.total
    }
  },
  watch: {
    visible: {
      handler(visible) {
        if (!visible) return
        this.getTableData()
      }
    },
    currentRow(newVal,oldVal){
        console.log(newVal,oldVal)
        if(newVal){
            this.isCurrentRow = false
        }else{
            this.isCurrentRow  = true
        }
    }
  },
  computed:{
    userInfo() {
      return this.$store.state.userInfo
    },
  }
}
</script>

<style lang="scss">
 .myCell .el-checkbox__input {

  display: none

}
.drawer-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  background: rgba($color: #000000, $alpha: 0.6);
  .drawer-container {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    .drawer-content {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 72%;
      height: 100%;
      background: #fff;
      box-shadow: -6px 0px 10px 0px rgba(112, 112, 112, 0.12);
      .drawer-header {
        display: flex;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding: 0 30px;

        > h2 {
          color: #333;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .drawer-body {
        flex: 1;
        border-top: 1px solid #e1e4eb;
        border-bottom: 1px solid #e1e4eb;
        overflow: auto;
        .goods-form-wrapper {
          margin: 30px 0 0px 0;
          padding: 0 25px;
          .formContent{
            display: flex;
            flex-wrap: wrap;
          }
          .searchItem{
            display: flex;
            width: 300px;
          }
          ::v-deep .el-input__suffix {
            right: 12px;
          }
        }
        .actions-btn {
          padding-left: 68px;
        }
        .goods-table-wrapper {
          width: 100%;
          margin-bottom: 44px;
          padding: 0 30px;
          height: 700px;
          .el-radio__label{
            display: none;
          }
        }
      }
      .drawer-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90px;
      }
    }
  }
}
.drawer-enter-active,
.drawer-leave-active {
  transition: 0.3s;
}
.drawer-enter {
  transform: translate(100%);
}
.drawer-leave-to {
  transform: translate(100%);
}
</style>
