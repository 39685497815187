var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-pagination",
    {
      attrs: {
        background: "",
        "current-page": _vm.currentPage,
        "page-sizes": _vm.pageSizes,
        "page-size": _vm.currentPageSize,
        layout: "slot,sizes,prev, pager, next, jumper",
        "page-count": _vm.totalPage,
        "hide-on-single-page": false
      },
      on: {
        "update:currentPage": function($event) {
          _vm.currentPage = $event
        },
        "update:current-page": function($event) {
          _vm.currentPage = $event
        },
        "update:pageSize": function($event) {
          _vm.currentPageSize = $event
        },
        "update:page-size": function($event) {
          _vm.currentPageSize = $event
        },
        "size-change": _vm.handleSizeChange,
        "current-change": _vm.handleCurrentChange
      }
    },
    [
      _c("em", { staticClass: "total-count" }, [
        _vm._v("总共" + _vm._s(_vm.totalCount) + "条记录")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }